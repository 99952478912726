body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
  height: inherit;
}

.App {
  height: inherit;
}

#outlined-multiline-flexible {
  color:red;
}
